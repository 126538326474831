import { stAnalytics } from "@repo/analytics";
import {
  Match,
  Show,
  Switch,
  createEffect,
  createSignal,
  createUniqueId,
  type ParentProps,
  type Signal,
} from "solid-js";
import { StIcon, type StIconComponent } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import { getScreenType } from "~/lib/ui/getScreenType";
import { useWire } from "~/wire";
import styles from "./CollectionsHierarchyItem.module.css";
interface Props extends ParentProps {
  label: string;
  collectionId: string;
  sourceParentId: string;
  sourceParentHasOptions: boolean;
  rootCollectionId: string;
  depth: number;
  initiallyOpen?: boolean;
  icon?: StIconComponent;
  class?: string;
  targetSignal: Signal<string>;
}

export const CollectionsHierarchyItem = (props: Props) => {
  const wire = useWire();
  const uniqueId = createUniqueId();
  const [target, setTarget] = props.targetSignal;
  const [selected, setSelected] = createSignal(target() === props.collectionId);
  const accordionId = `hierarchy-accordion-${props.label.replace(/\s/g, "-").toLowerCase()}-${uniqueId}`;
  const [toggle, setToggle] = createSignal(props.initiallyOpen ?? false);

  const children = () => {
    const collections = wire.services.collections
      .getChildren(props.collectionId)
      .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: "base", ignorePunctuation: true }));
    //  For situations where we find the parent collection in the tree, we need to check it has options available.
    if (collections.filter((entry) => entry.id === props.sourceParentId).length > 0 && props.sourceParentHasOptions) {
      return collections;
    }
    //  Otherwise exclude it from the array.
    return collections.filter((entry) => entry.id !== props.sourceParentId);
  };

  const indent = () => {
    if (children().length > 0) {
      if (props.depth === 0) return "0px";
      return `padding-left: calc(${props.depth * 18}px);`; // width-diff Account for centering of icon
    }
    if (props.depth === 1) return "padding-left: 44px;";
    return `padding-left: calc(${props.depth * 18}px + 26px);`; // calc(depth * 36 - (width * 0.5 + (width-diff * 0.5))
  };

  createEffect(() => {
    if (target() === props.collectionId) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  });

  //  Sets this collection as the target Collection.
  const select = () => {
    if (props.collectionId !== props.sourceParentId) {
      setTarget(props.collectionId);
    } else {
      setTarget("");
    }
  };

  return (
    <>
      <div
        class={classNames(
          styles["collections-hierarchy-item"],
          selected() ? styles["collections-hierarchy-item--selected"] : "",
          props.collectionId === props.rootCollectionId ? styles["collections-hierarchy-item--root"] : "",
          props.collectionId === props.sourceParentId ? styles["collections-hierarchy-item--inactive"] : "",
          children().length === 0 ? styles["collections-hierarchy-item--empty"] : "",
        )}
        style={indent()}
      >
        {/* Expand/Contract */}
        <Switch>
          <Match when={children().length > 0}>
            <button
              type="button"
              data-component="collections-hierarchy-item-trigger"
              on:click={(event: Event) => {
                setToggle(!toggle());
                stAnalytics.track("click_tracking", {
                  cta: "expand_move_collection_state",
                  position: "left_drawer",
                  screen: getScreenType(),
                });
                event.preventDefault();
                event.stopImmediatePropagation();
              }}
              aria-controls={accordionId}
              aria-expanded={toggle()}
              class={styles["collections-hierarchy-item__trigger"]}
              tabIndex={0}
            >
              <span class={styles["collections-hierarchy-item__indicator-container"]}>
                <span
                  class={classNames(
                    styles["collections-hierarchy-item__indicator"],
                    toggle() ? styles["collections-hierarchy-item__indicator--open"] : undefined,
                  )}
                />
              </span>
              <span class="screen-reader">{`${toggle() ? "Collapse" : "Expand"} the ${props.label} list.`}</span>
            </button>
            <button
              type="button"
              class={styles["collections-hierarchy-item__action"]}
              tabIndex={0}
              onClick={select}
              disabled={props.collectionId === props.sourceParentId}
            >
              <Show when={props.icon}>
                <StIcon icon={props.icon!} class={styles["collections-hierarchy-item__icon"]} size="1.5rem" />
              </Show>
              <span class="sr-only">Move to the</span>
              <span
                class={classNames(styles["collections-hierarchy-item__label"])}
              >{`${props.label}${props.collectionId === props.sourceParentId ? "" : ""}`}</span>
              <span class="sr-only">Collection.</span>
            </button>
          </Match>
          <Match when={children().length === 0}>
            {/* Load Content */}
            <button
              type="button"
              class={styles["collections-hierarchy-item__action"]}
              tabIndex={0}
              onClick={select}
              disabled={props.collectionId === props.sourceParentId}
            >
              <Show when={props.icon}>
                <StIcon icon={props.icon!} class={styles["collections-hierarchy-item__icon"]} size="1.5rem" />
              </Show>
              <span class="sr-only">Move to the</span>
              <span
                class={classNames(styles["collections-hierarchy-item__label"])}
              >{`${props.label}${props.collectionId === props.sourceParentId ? " (current)" : ""}`}</span>
              <span class="sr-only">Collection.</span>
            </button>
          </Match>
        </Switch>
      </div>
      <Show when={children().length > 0}>
        <div role="region" id={accordionId} class={styles["collections-hierarchy-item__content"]}>
          <Show when={toggle()}>
            <menu>{props.children}</menu>
          </Show>
        </div>
      </Show>
      {/* <Show when={props.selected}>
        <ShadowCollectionCTA depth={props.depth + 1} />
      </Show> */}
    </>
  );
};
