import { classNames } from "~/lib/classNames";
import styles from "./HorizontalRule.module.css";

type Modifier = "thin" | "no-margin";
interface Props {
  modifiers?: Modifier[];
}

export const HorizontalRule = (props: Props) => (
  <hr
    class={classNames(
      styles["horizontal-rule"],
      props.modifiers ? props.modifiers.map((modifier) => styles[`horizontal-rule--${modifier}`]).join(" ") : "",
    )}
  />
);
