import type { ValidationMethods } from "@repo/validation";
import type { IconTypes } from "solid-icons";
import { type Accessor, Show, createEffect, createSignal } from "solid-js";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./LargeInputText.module.css";

type Modifier = "outline" | "overlay";
interface Props {
  placeholder: string;
  label: string;
  name: string;
  icon?: IconTypes;
  required?: boolean;
  value: string;
  validation?: Array<keyof typeof ValidationMethods>;
  errorMessage?: string;
  class?: string;
  modifier?: Modifier;
  size?: "tight";
  focus?: Accessor<boolean>;
  onInput?: (e: InputEvent) => void;
  showError?: boolean;
}

export const LargeInputText = (props: Props) => {
  const [inputRef, setInputRef] = createSignal<HTMLInputElement | null>(null);
  createEffect(() => {
    const input = inputRef();
    if (input && props.focus?.()) {
      input.focus();
    }
  });
  return (
    <div
      class={classNames(
        styles["large-input-text"],
        props.class,
        props.modifier === "outline" ? styles["large-input-text--outline"] : "",
        props.modifier === "overlay" ? styles["large-input-text--overlay"] : "",
        props.size === "tight" ? styles["large-input-text--tight"] : "",
        props.icon ? styles["large-input-text--has-icon"] : "",
        // props.required ? styles.largeInputTextRequired : "",
        props.errorMessage ? styles["large-input-text--invalid"] : "",
      )}
    >
      <Show when={props.icon}>
        <StIcon icon={props.icon!} class={styles["large-input-text__icon"]} size="1.75rem" />
      </Show>
      <input
        type="text"
        ref={setInputRef}
        class={styles["large-input-text__field"]}
        placeholder={props.placeholder}
        aria-label={props.label}
        required={props.required}
        name={props.name}
        value={props.value ?? ""}
        aria-invalid={props.required && !props.value}
        data-validation={props.validation ? props.validation.join(",") : undefined}
        autocomplete="off"
        data-1p-ignore
        onInput={props.onInput}
      />
      <Show when={props.showError}>
        <span class={styles["large-input-text__error"]} aria-live="polite">
          {props.errorMessage}
        </span>
      </Show>
    </div>
  );
};
