import type { ValidationMethods } from "@repo/validation";
import type { IconTypes } from "solid-icons";
import { Show } from "solid-js";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./TextArea.module.css";

interface Props {
  placeholder: string;
  class?: string;
  label: string;
  name: string;
  icon?: IconTypes;
  required?: boolean;
  value?: string;
  validation?: Array<keyof typeof ValidationMethods>;
}

export const TextArea = (props: Props) => {
  return (
    <div class={classNames(styles["text-area"], props.icon ? styles["text-area--has-icon"] : "", props.class)}>
      <Show when={props.icon}>
        <StIcon icon={props.icon!} class={styles["text-area__icon"]} size="1.75rem" />
      </Show>
      <textarea
        class={styles["text-area__field"]}
        placeholder={props.placeholder}
        aria-label={props.label}
        name={props.name}
        required={props.required}
        data-validation={props.validation}
        value={props.value ?? ""}
      />
    </div>
  );
};
