import { BsQuestion } from "solid-icons/bs";
import { type JSX, createSignal, createUniqueId, onCleanup, onMount } from "solid-js";
import { Portal } from "solid-js/web";
import { BurgerButton } from "~/components/BurgerButton";
import { StorytellLogo } from "~/components/StorytellLogo";
import { UserAvatarMenu } from "~/components/UserAvatarMenu";
import { LeftDrawer } from "~/components/containers/LeftDrawer";
import { HelpContextMenu } from "~/components/context-menu/HelpContextMenu";
import { IconCTA } from "~/components/cta/IconCTA";
import { classNames } from "~/lib/classNames";
import { useUIState } from "~/ui/UIState";
import { GLOBAL_ELEMENT_ATTRIBUTES } from "~/ui/global-element-attributes";
import { GLOBAL_ELEMENT_IDS } from "~/ui/global-element-ids";
import mobileStyles from "./MobileHeader.module.css";
import styles from "./TwoColumnLayout.module.css";
interface Props {
  sidebar: JSX.Element;
  content: JSX.Element;
  toggleTop?: JSX.Element;
}

//  TODO - Rethink the TwoColumnLayout component and mobile navigation
export const TwoColumnLayout = (props: Props) => {
  const state = useUIState();
  const [helpRef, setHelpRef] = createSignal<HTMLElement>((<></>) as HTMLElement);
  const helpId = `help-menu-mobil-${createUniqueId()}`;
  const [open, setOpen] = state.leftDrawer;
  const [, setIsTwoColumn] = useUIState().isTwoColumn;
  setIsTwoColumn(true);

  let container: HTMLElement | undefined;
  let callback: ReturnType<typeof requestAnimationFrame> | undefined;
  let lastPosition = 0;

  const frame = () => {
    const doc = document.documentElement;
    if (container) {
      const position = container.scrollTop;
      if (position > lastPosition && doc.getAttribute(GLOBAL_ELEMENT_ATTRIBUTES.scrollDirection) === "up") {
        doc.setAttribute(GLOBAL_ELEMENT_ATTRIBUTES.scrollDirection, "down");
      } else if (position < lastPosition && doc.getAttribute(GLOBAL_ELEMENT_ATTRIBUTES.scrollDirection) === "down") {
        doc.setAttribute(GLOBAL_ELEMENT_ATTRIBUTES.scrollDirection, "up");
      }
      lastPosition = position;

      if (position > 0) {
        document.documentElement.setAttribute(GLOBAL_ELEMENT_ATTRIBUTES.scrolled, "true");
      } else {
        document.documentElement.setAttribute(GLOBAL_ELEMENT_ATTRIBUTES.scrolled, "false");
      }
      callback = undefined;
    }
  };

  const onScroll = () => {
    if (!callback) {
      callback = requestAnimationFrame(frame);
    }
  };

  onMount(() => {
    if (props.toggleTop) {
      globalThis.addEventListener("scroll", onScroll);
      onCleanup(() => {
        globalThis.removeEventListener("scroll", onScroll);
        document.documentElement.removeAttribute("data-scroll");
        if (callback) {
          cancelAnimationFrame(callback);
        }
      });
    }
  });

  return (
    <div id={GLOBAL_ELEMENT_IDS.main} data-component="container" class={styles["two-column-layout"]}>
      <div class={mobileStyles["collections-top__mobile"]}>
        <div class={mobileStyles["collections-top__left"]}>
          <BurgerButton controls="my-menu-id" open={open()} setOpen={setOpen} />
        </div>
        <div class={mobileStyles["collections-top__middle"]}>
          <StorytellLogo />
        </div>
        <div>
          <IconCTA
            data-test-id="view-documentation"
            accessibleLabel="View documentation"
            icon={BsQuestion}
            modifiers={["small", "primary-filled"]}
            ref={(ref: HTMLElement) => {
              setHelpRef(ref);
            }}
            class={mobileStyles["collections-top__right-docs"]}
          />
          <Portal mount={document.getElementById(GLOBAL_ELEMENT_IDS.portal) ?? document.body}>
            <HelpContextMenu bound={helpRef()} id={helpId} placement="top-start" />
          </Portal>
          <UserAvatarMenu />
        </div>
      </div>
      <LeftDrawer
        class={classNames(
          styles["two-column-layout__sidebar"],
          open() ? styles["two-column-layout__sidebar--open"] : "",
        )}
      >
        <nav data-component="sidebar" class={styles["two-column-layout__sidebar-content"]}>
          {/* <div class={styles.twoColumnLayoutSidebarResize} /> */}
          {props.sidebar}
        </nav>
      </LeftDrawer>
      <main
        data-component="content"
        id={GLOBAL_ELEMENT_IDS.mainContent}
        tabIndex={-1}
        class={styles["two-column-layout__content"]}
        onScroll={props.toggleTop ? onScroll : undefined}
        ref={container}
      >
        {props.content}
      </main>
    </div>
  );
};
