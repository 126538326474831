import { TbRefresh } from "solid-icons/tb";
import { type Component, ErrorBoundary, For, Match, Show, startTransition, Suspense, Switch } from "solid-js";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { Loading } from "~/components/Loading";
import { SidebarCollection } from "~/components/SideBarCollection";
import { SideBarSectionAccordion } from "~/components/SideBarSectionAccordion";
import { CollectionKind } from "~/domains/collections/collections.types";
import { useWire } from "~/wire";
import styles from "./SidebarCollections.module.css";

export const SidebarCollections: Component = () => {
  const wire = useWire();
  const collections = wire.services.collections;
  const [tree, { refetch: refetchTree }] = collections.globalResourceTree;

  const roots = () => (!collections.getRootsLoaded() ? [] : collections.getRootCollections());

  // const sharedRoot = () => roots().find((r) => r.collectionKind !== CollectionKind.Favorites);
  // const sharedCollections = () => {
  //   return collections.getChildrenIds(sharedRoot()?.id || "");
  // };

  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary
        fallback={
          <div class={styles["sidebar-collections__error-boundary"]}>
            <p class={styles["sidebar-collections__error-boundary-text"]}>
              There was an error loading your Collections.
            </p>
            <div>
              <SecondaryCTA
                size="small"
                data-test-id="sidebar-collections-error-boundary"
                accessiblePrefix="Click to "
                icon={TbRefresh}
                label="Reload"
                onClick={() => startTransition(() => refetchTree())}
              />
            </div>
          </div>
        }
      >
        <Show when={collections.getRootsLoaded() && tree()} fallback={<Loading />}>
          <SideBarSectionAccordion label="Collections" initiallyOpen depth={0}>
            <For each={roots()}>
              {(root) => (
                <Switch>
                  <Match when={root.collectionKind !== CollectionKind.Favorites}>
                    <SidebarCollection
                      onCollectionCreated={refetchTree}
                      root
                      collectionId={root.id}
                      depth={0}
                      refreshTree={refetchTree}
                    />
                  </Match>
                  <Match when={collections.getChildrenIds(root.id).length === 0}>{null}</Match>
                  <Match when={true}>
                    <For each={collections.getChildrenIds(root.id)}>
                      {(child) => (
                        <SidebarCollection
                          onCollectionCreated={refetchTree}
                          collectionId={child}
                          depth={0}
                          refreshTree={refetchTree}
                        />
                      )}
                    </For>
                  </Match>
                </Switch>
              )}
            </For>
          </SideBarSectionAccordion>
        </Show>
      </ErrorBoundary>
    </Suspense>
  );
};

// const CreateSharedCollection: Component<{ sharedRootId: string; hasShared: boolean }> = (props) => {
//   const wire = useWire();
//   const state = useUIState();
//   const [, setSidebarOpen] = state.leftDrawer;
//   const [, setModalOpen] = state.modal;
//   const [, setModalContents] = state.modalContents;
//   const [, setOpenShareDrawer] = state.openShareDrawer;
//   const navigate = useNavigate();

//   return (
//     <PrimaryCTA
//       data-test-id="sidebar-start-collaborating"
//       icon={TbUsersGroup}
//       accessiblePrefix="Click to "
//       label={props.hasShared ? "New shared Collection" : "Start collaborating"}
//       size={props.hasShared ? "small" : "medium"}
//       class="mt-3"
//       onClick={() => {
//         setModalContents(() => () => (
//           <CreateSharedCollectionModal
//             id="create-shared-collection-modal"
//             onCreate={async (name, description) => {
//               const res = await wire.services.collections.apiCreateCollection(
//                 wire.services.collections.getDataOrFail(props.sharedRootId),
//                 name,
//                 description,
//               );
//               setSidebarOpen(false);
//               setOpenShareDrawer(true);
//               navigate(urls.collection(res.data.id));

//               setModalOpen("");
//               setModalContents(null);
//             }}
//           />
//         ));
//         setModalOpen("create-shared-collection-modal");
//       }}
//     />
//   );
// };
