import {
  createSignal,
  onMount,
  onCleanup,
  createEffect,
  Show,
  createResource,
  For,
  Suspense,
  startTransition,
  type Component,
  Match,
  Switch,
} from "solid-js";
import { Dynamic, Portal } from "solid-js/web";
import { LargeSearchBar } from "./LargeSearchBar";
import { createElementSizeObserver } from "~/lib/createElementSizeObserver";
import {
  type AssetsAndCollectionsSearchResult,
  searchAssetsAndCollections,
} from "~/lib/api/searchAssetsAndCollections";
import { useWire } from "~/wire";
import { Spinner } from "./_original/Spinner";
import { twMerge } from "tailwind-merge";
import type { MentionItemType } from "~/domains/chat/prompt/extensions/Mentions/Mentions.extension";
import { getRootCollectionUIOverloads } from "~/domains/collections/collections.helpers";
import type { CollectionSnapshot } from "~/domains/collections/collections.types";
import { originalFilenameToMimeType } from "~/lib/originalFilenameToMimeType";
import { useFileTypeIcon, type FileTypeIcon } from "~/ui/useFileTypeIcon";
import { StIcon } from "./icons";
import { A, useNavigate } from "@solidjs/router";
import { urls } from "~/lib/urls";

export const SearchBar = () => {
  const wire = useWire();
  const navigate = useNavigate();
  const [staticRef, setStaticRef] = createSignal<HTMLElement>();
  const [floatingRef, setFloatingRef] = createSignal<HTMLElement>();
  const [searchOpen, setSearchOpen] = createSignal(false);
  const [searchQuery, setSearchQuery] = createSignal<string>("");

  const [selected, setSelected] = createSignal(1);

  const [_results] = createResource(
    () => (searchOpen() ? searchQuery() : false),
    async (query) => {
      return searchAssetsAndCollections(query, wire);
    },
  );

  const results = () => {
    const res = _results();
    if (!res) return [];

    const collections = res.filter((item) => item.type === "collection");
    const assets = res.filter((item) => item.type === "asset");

    return [
      ...(collections.length > 0 ? ([{ label: "Collections", type: "label" }] as const) : []),
      ...collections,
      ...(assets.length > 0 ? ([{ label: "Assets", type: "label" }] as const) : []),
      ...assets,
    ];
  };

  const size = createElementSizeObserver(staticRef);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape" && searchOpen()) {
      setSearchOpen(false);
    }
    if (!searchOpen() && e.key === "/" && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      e.stopPropagation();
      setSearchOpen(true);
    }

    const skip: number[] = [];
    for (let i = 0; i < results().length; i++) {
      if (results()[i]?.type === "label") {
        skip.push(i);
      }
    }

    if (e.key === "Enter" && searchOpen()) {
      e.preventDefault();
      e.stopPropagation();
      const item = results()[selected()];

      if (item?.type === "collection") {
        navigate(urls.collection(item.data.id));
      }
      if (item?.type === "asset") {
        navigate(urls.collection(item.associatedCollection, { assetId: item.data.id }));
      }
      setSearchOpen(false);
      setSearchQuery("");
    }

    if (e.key === "ArrowDown" && searchOpen()) {
      e.preventDefault();
      e.stopPropagation();
      let next = selected() + 1;
      while (skip.includes(next)) {
        next++;
      }
      if (next > results().length - 1) {
        next = 1;
      }
      setSelected(next);
    }
    if (e.key === "ArrowUp" && searchOpen()) {
      e.preventDefault();
      e.stopPropagation();
      let prev = selected() - 1;
      while (skip.includes(prev)) {
        prev--;
      }
      if (prev < 0) {
        prev = results().length - 1;
      }
      setSelected(prev);
    }
  };
  onMount(() => {
    window.addEventListener("keydown", onKeyDown);
    onCleanup(() => {
      window.removeEventListener("keydown", onKeyDown);
    });
  });

  createEffect(() => {
    const ref = floatingRef();
    if (ref) {
      ref.querySelector("input")?.focus();
    }
  });
  createEffect(() => {
    if (!searchOpen()) {
      setFloatingRef(undefined);
      setSearchQuery("");
      setSelected(1);
    }
  });

  return (
    <div ref={setStaticRef}>
      <div>
        <LargeSearchBar
          inputProps={{
            onFocus: (e) => {
              e.target.blur();
              setSearchOpen(true);
            },
            class: "cursor-pointer",
          }}
          placeholder="Search Collections & Assets"
          label="Search Collections & Assets"
        />
      </div>

      <Show when={searchOpen()}>
        <Portal mount={document.body}>
          <div
            class="fixed inset-0 bg-black/50 dark:bg-white/10 z-max animate-fade-in"
            on:click={{
              capture: true,
              handleEvent: (e) => {
                e.preventDefault();
                e.stopImmediatePropagation();

                setSearchOpen(false);
              },
            }}
          />
          <div
            ref={setFloatingRef}
            class="fixed bg-background border border-background-decoration z-max rounded-lg p-2"
            style={{
              top: `calc(${size.top}px - ${0.5}rem - 1px)`,
              left: `calc(${size.left}px - ${0.5}rem - 1px)`,
              width: `calc(${size.width}px + ${2 * 0.5}rem + 2px)`,
            }}
          >
            <LargeSearchBar
              placeholder="Search Collections & Assets"
              label="Search Collections & Assets"
              value={searchQuery()}
              setValue={(val) => {
                startTransition(() => {
                  setSearchQuery(val);
                });
              }}
            />

            <Suspense
              fallback={
                <div class="h-60 grid place-content-center text-on-background">
                  <Spinner class="size-6" />
                </div>
              }
            >
              <div class="flex flex-col gap-2 py-2 px-3 max-h-[70vh] overflow-y-auto">
                <For each={results()}>
                  {(item, index) => (
                    <ResultItem
                      onSelected={() => {
                        setSearchOpen(false);
                        setSearchQuery("");
                      }}
                      item={item}
                      index={index()}
                      focused={selected() === index()}
                    />
                  )}
                </For>
              </div>
            </Suspense>
          </div>
        </Portal>
      </Show>
    </div>
  );
};

const ResultItem: Component<{
  item: AssetsAndCollectionsSearchResult[number] | { label: string; type: "label" };
  index: number;
  focused: boolean;
  onSelected: () => void;
}> = (props) => {
  const wire = useWire();
  const rootOverride = () =>
    props.item.type === "collection" ? getRootCollectionUIOverloads(props.item.data) : undefined;
  const iconTheme = () =>
    props.item.type === "asset"
      ? useFileTypeIcon(originalFilenameToMimeType(props.item.data.originalFilename) as FileTypeIcon)
      : undefined;

  const collection = () =>
    props.item.type === "asset"
      ? getRootCollectionUIOverloads(wire.services.collections.getCollection(props.item.associatedCollection))
      : undefined;

  let el!: HTMLElement;
  createEffect(() => {
    if (props.focused) {
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  });

  return (
    <Switch>
      <Match when={props.item.type === "label" && props.item.label}>
        {(label) => (
          <span
            ref={el}
            class="flex-shrink-0 block mt-3 select-nonde text-xs text-on-background opacity-70 leading-tight truncate"
          >
            {label()}
          </span>
        )}
      </Match>
      <Match when={rootOverride()}>
        {(data) => (
          <A
            ref={el as HTMLAnchorElement}
            data-focus={props.focused}
            class="flex-shrink-0 rounded text-base px-2 py-1 text-on-background hover:bg-background-hover data-[focus=true]:bg-background-hover flex items-center gap-2 select-none cursor-pointer"
            href={urls.collection(props.item.type === "collection" ? props.item.data.id : "")}
            onClick={props.onSelected}
          >
            <StIcon icon={data().icon} size={20} />
            <span class={twMerge("ml-2 truncate font-medium", props.focused && "font-medium")}>
              {data().label || ((props.item as MentionItemType).data as CollectionSnapshot).label}
            </span>
          </A>
        )}
      </Match>

      <Match when={props.item.type === "asset" && props.item}>
        {(asset) => (
          <A
            ref={el as HTMLAnchorElement}
            href={urls.collection(asset().associatedCollection, { assetId: asset().data.id })}
            data-focus={props.focused}
            class="flex-shrink-0 rounded text-base px-2 py-1 text-on-background hover:bg-background-hover data-[focus=true]:bg-background-hover flex items-center gap-2 select-none cursor-pointer"
            onClick={props.onSelected}
          >
            <Dynamic component={iconTheme()?.icon} size={16} class="ml-[1px]" />
            <span class="flex flex-col ml-2 truncate">
              <span class={twMerge("truncate font-medium", props.focused && "font-medium")}>
                {asset().data.displayName || asset().data.originalFilename}
              </span>
              <Show when={asset().data.displayName}>
                <span class="text-sm text-on-background leading-tight truncate">{asset().data.originalFilename}</span>
              </Show>
              <Show when={collection()}>
                {(collection) => (
                  <span class="text-xs text-on-background opacity-50 leading-tight truncate flex items-center gap-0.5 mt-1">
                    <StIcon icon={collection().icon} size={12} />
                    {collection().label}
                  </span>
                )}
              </Show>
            </span>
          </A>
        )}
      </Match>
    </Switch>
  );
};
