import { Show, type JSXElement } from "solid-js";
import { UserAvatar, type UserAvatarProps } from "~/components/UserAvatar";
import { classNames } from "~/lib/classNames";
import styles from "./InlineUserAvatar.module.css";

export const InlineUserAvatar = (props: UserAvatarProps & { subtitle?: JSXElement; isYou?: boolean }) => {
  return (
    <p
      class={classNames(styles["inline-user-avatar"], !props.subtitle ? styles["inline-user-avatar--no-subtitle"] : "")}
    >
      <span class={styles["inline-user-avatar__portrait"]}>
        <UserAvatar {...props} />
      </span>

      <span class={styles["inline-user-avatar__text-container"]}>
        <span class={styles["inline-user-avatar__name"]}>
          <span class={styles["inline-user-avatar__text"]}>{props.name}</span>
          <Show when={props.isYou}>
            <span class={styles["inline-user-avatar__you"]}>(You)</span>
          </Show>
        </span>
        <Show when={props.subtitle}>
          <span>{props.subtitle}</span>
        </Show>
      </span>
    </p>
  );
};
