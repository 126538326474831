import type { curator, operation } from "@repo/client";
import { TbArrowsMove, TbKeyframes } from "solid-icons/tb";
import { For, Match, Show, Suspense, Switch, createEffect, createSignal, onMount } from "solid-js";
import { CollectionsHierarchy } from "~/components/CollectionsHierarchy";
import type { CollectionsModalProps } from "~/components/containers/CollectionsModal";
import { DestructiveCTA } from "~/components/cta/DestruciveCTA";
import { PrimaryCTA } from "~/components/cta/PrimaryCTA";
import { InfoPanel } from "~/components/InfoPanel";
import { getRootCollectionUIOverloads } from "~/domains/collections/collections.helpers";
import { CollectionKind, type CollectionSnapshot } from "~/domains/collections/collections.types";
import { VisibleCollections } from "~/domains/collections/collections.ui";
import { formatCollectionName } from "~/lib/ui/formatCollectionName";
import { DEFAULT_ICONS } from "~/ui/default-icons";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";
import { ErrorModal } from "./ErrorModal";
import styles from "./MoveCollectionModal.module.css";

interface Props extends CollectionsModalProps {
  onMove: (parentCollectionId: string) => Promise<operation.Response<curator.CollectionState[]>>;
  label: string;
  sourceParentId: string;
  collectionId: string;
  rootCollectionId: string;
  rootHasOptions: boolean;
  sourceParentHasOptions: boolean;
}

export const MoveCollectionModal = (props: Props) => {
  const state = useUIState();
  const [modalOpen, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;
  const [error, setError] = createSignal("");
  const [, setFocus] = createSignal(false);
  const targetSignal = createSignal("");

  const [isShare, setIsShared] = createSignal(false);

  const [target] = targetSignal;
  let submitting = false;
  const onSubmit = async (event: SubmitEvent) => {
    if (!submitting) {
      submitting = true;
      const res = await props.onMove(target());
      refetchCollectionAccess();
      if (res.code !== "ok") {
        setError(res.message);
      } else {
        setModalOpen("");
        setModalContents(null);
      }
    }
    event.stopImmediatePropagation();
    event.preventDefault();
  };
  const wire = useWire();
  const collections = wire.services.collections;
  const roots = () =>
    !collections.getRootsLoaded()
      ? []
      : ([CollectionKind.Favorites, CollectionKind.Org, CollectionKind.Personal].map(
          collections.getRootCollectionByKind,
        ) as CollectionSnapshot[]);

  createEffect(() => {
    if (modalOpen() === props.id) {
      setFocus(true);
    }
    if (modalOpen() === "") {
      setFocus(false);
    }
  });

  const isSharedCollection =
    wire.services.collections.getCollectionRoot(props.collectionId)?.collectionKind === CollectionKind.Org;
  const [collectionAccess, { refetch: refetchCollectionAccess }] = collections.resourceCollectionAccess(
    () => props.collectionId,
  );

  onMount(() => {
    submitting = false;
  });

  return (
    <Suspense>
      <Switch fallback={<ErrorModal title="Unable to move your Collection." errorMessage={error()} />}>
        <Match when={error() === ""}>
          <div id={props.id} class={styles["move-collection-modal"]}>
            <p class={styles["move-collection-modal__title"]}>
              <span
                class={styles["move-collection-modal__title-text"]}
              >{`Move your ${formatCollectionName(props.label)}.`}</span>
            </p>
            <Show
              when={
                isSharedCollection &&
                wire.services.collections.getCollectionRoot(target())?.collectionKind === CollectionKind.Personal &&
                collectionAccess()?.data.records.length !== 1
              }
            >
              <div class={styles["move-collection-modal__info"]}>
                <InfoPanel
                  title="This Collection has been shared with others."
                  icon={DEFAULT_ICONS.info}
                  variant="danger"
                  subtext={["By moving this under your Personal Collection, all other users will lose access."]}
                />
              </div>
            </Show>
            <menu class={styles["move-collection-modal__hierarchy"]}>
              <For each={roots().slice(VisibleCollections.BothSharedAndPersonal)}>
                {(root) => (
                  <Show when={props.rootHasOptions}>
                    <li>
                      <CollectionsHierarchy
                        rootCollectionId={root.id}
                        sourceParentHasOptions={props.sourceParentHasOptions}
                        sourceParentId={props.sourceParentId ?? ""}
                        targetSignal={targetSignal}
                        sourceCollectionId={props.collectionId}
                        collectionId={root.id}
                        depth={0}
                        icon={getRootCollectionUIOverloads(root)?.icon ?? TbKeyframes}
                      />
                    </li>
                  </Show>
                )}
              </For>
            </menu>
            <div class={styles["move-collection-modal__buttons"]}>
              <div aria-hidden class={styles["move-collection-modal__buttons-left"]} />
              <div class={styles["move-collection-modal__buttons-right"]}>
                <Switch
                  fallback={
                    <PrimaryCTA
                      data-test-id="move-collections-modal-submit"
                      accessibleSuffix=""
                      label="Move Collection"
                      type="submit"
                      icon={TbArrowsMove}
                      inactive={
                        target() === "" ||
                        (isSharedCollection &&
                          wire.services.collections.getCollectionRoot(target())?.collectionKind ===
                            CollectionKind.Personal &&
                          collectionAccess.length > 1)
                      }
                      onClick={onSubmit}
                    />
                  }
                >
                  <Match
                    when={
                      isSharedCollection &&
                      wire.services.collections.getCollectionRoot(target())?.collectionKind ===
                        CollectionKind.Personal &&
                      collectionAccess()?.data.records.length !== 1
                    }
                  >
                    <DestructiveCTA
                      data-test-id="delete-collections-modal-submit"
                      accessibleSuffix=""
                      label="Revoke access & move Collection"
                      confirmLabel="Yes, revoke access and move Collection."
                      type="button"
                      icon={TbArrowsMove}
                      onClick={onSubmit}
                    />
                  </Match>
                </Switch>
              </div>
            </div>
          </div>
        </Match>
      </Switch>
    </Suspense>
  );
};
