import { FiSave } from "solid-icons/fi";
import { Suspense, createEffect, createSignal, onCleanup, onMount } from "solid-js";
import type { CollectionsModalProps } from "~/components/containers/CollectionsModal";
import { PrimaryCTA } from "~/components/cta/PrimaryCTA";
import { LargeInputText } from "~/components/fields/LargeInputText";
import { TextArea } from "~/components/fields/TextArea";
import { useUIState } from "~/ui/UIState";
import { type FormValidationData, useFormValidation } from "~/ui/useFormValidation";
import styles from "./EditAssetModal.module.css";

interface Props {
  id: string;
  assetId: string;
  onUpdate?: (assetId: string, displayName: string, summary?: string) => void;
  displayName: string;
  summary: string;
  onClose: () => void;
}

export const EditAssetModal = (props: Props) => {
  const [modal] = useUIState().modal;
  const [form, setForm] = createSignal((<></>) as HTMLFormElement);
  const [focus, setFocus] = createSignal(false);
  const [canSubmit, setCanSubmit] = createSignal(false);
  const [data, setData] = createSignal<FormValidationData>();
  let submitting = false;
  const onSubmit = (event: SubmitEvent) => {
    if (event.target instanceof HTMLFormElement && !submitting) {
      submitting = true;
      const d = data();
      if (!d || !d.values) return;
      const displayName = d.values.displayName ?? props.displayName;
      const summary = d.values.summary ?? props.summary;
      props.onUpdate?.(props.assetId, displayName, summary);
    }
    event.stopImmediatePropagation();
    event.preventDefault();
  };

  createEffect(() => {
    if (modal() === props.id) {
      setFocus(true);
      return;
    }
    if (modal() === "") {
      setFocus(false);
    }
  });

  onMount(() => {
    submitting = false;
    if (form()) {
      const { validates, data, eventHandler } = useFormValidation(form());
      form().addEventListener("input", eventHandler);
      form().addEventListener("change", eventHandler);
      createEffect(() => {
        setCanSubmit(validates());
        setData(data);
      });

      onCleanup(() => {
        form().removeEventListener("input", eventHandler);
        form().removeEventListener("change", eventHandler);
      });
    }
  });

  return (
    <Suspense>
      <form ref={(ref) => setForm(ref)} class={styles["edit-asset-modal"]} onSubmit={onSubmit}>
        <p class={styles["edit-asset-modal__title"]}>
          <span class={styles["edit-asset-modal__title-text"]}>Edit Asset</span>
        </p>
        <LargeInputText
          required={true}
          focus={focus}
          placeholder="Choose a name less than 255 characters."
          label="Name"
          name="displayName"
          validation={["required", "assetDisplayName"]}
          value={props.displayName}
          class={styles["edit-asset-modal__input"]}
          errorMessage={data()?.messages.name}
          modifier="overlay"
        />
        <TextArea
          required={true}
          placeholder="Add a summary between 3 and 2,048 characters."
          label="Summary"
          validation={["required", "assetSummary"]}
          name="summary"
          value={props.summary ?? ""}
          class={styles["edit-asset-modal__textarea"]}
        />
        <div class={styles["edit-asset-modal__buttons"]}>
          <div aria-hidden class={styles["edit-asset-modal__buttons-left"]} />
          <div class={styles["edit-asset-modal__buttons-right"]}>
            <PrimaryCTA
              data-test-id="rename-collections-modal-create"
              accessibleSuffix=""
              label="Update Asset"
              type="submit"
              icon={FiSave}
              inactive={!canSubmit()}
            />
          </div>
        </div>
      </form>
    </Suspense>
  );
};
